import axiosInstance from "@app/axiosInstance";
import { T_ReportForm } from "@app/entities/report/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";
import { v4 as uuidv4 } from "uuid";

export const useReportMutations = () => {
  const queryClient = useQueryClient();
  const idempotencyId = uuidv4();

  const { mutate: claimSwarm, isPending: isClaimSwarmPending } = useMutation({
    mutationFn: (reportId: string) =>
      axiosInstance.get(`/member/report/${reportId}/claim`),
    onSuccess: () => {
      notification.success({
        description:
          "You should receive a text message shortly with swarm location details.",
        message: "Successfully claimed report!",
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes("reports"),
      });
    },
    onError: (error) => {
      // TODO: @withakerik - Add error handling for failed claim. (we don't know what error.message is or if it even exists)
      notification.error({
        description: error.message,
        message: "Failed to claim report.",
      });
    },
  });

  const { mutate: submitReport, isPending: isSubmitReportPending } =
    useMutation({
      mutationKey: ["submitReport"],
      mutationFn: (
        report: T_ReportForm & { recaptchaToken: string } & {
          file: File | null;
        },
      ) => {
        const formData = new FormData();

        for (const key in report) {
          if (Object.prototype.hasOwnProperty.call(report, key)) {
            if (key === "file" && report.file) {
              formData.append("image", report.file);
            } else {
              formData.append(key, (report as any)[key]);
            }
          }
        }

        formData.append("idempotencyId", idempotencyId);

        return axiosInstance.post("/report", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      },
    });

  const { isPending: isUnclaimReportPending, mutate: unclaimReport } =
    useMutation({
      mutationKey: ["unclaimReport"],
      mutationFn: ({ reportId }: { reportId: number }) =>
        axiosInstance.put(`/member/report/unclaim`, { reportId }),
      onSuccess: () => {
        notification.success({
          description: "Report has been unclaimed",
          message: "Success",
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes("reports"),
        });
      },
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to unclaim report" });
      },
    });

  const { isPending: isMarkingReportBad, mutate: markReportBad } = useMutation({
    mutationKey: ["markReportBad"],
    mutationFn: ({ reportId, reason }: { reportId: number; reason: string }) =>
      axiosInstance.put(`/member/report/bad`, { reportId, reason }),
    onSuccess: () => {
      notification.success({
        description: "Report has been marked as bad",
        message: "Success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes("reports"),
      });
    },
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to mark report as bad" });
    },
  });

  const isMutationPending =
    isClaimSwarmPending ||
    isSubmitReportPending ||
    isUnclaimReportPending ||
    isMarkingReportBad;
  return {
    isMutationPending,
    claimSwarm,
    markReportBad,
    submitReport,
    unclaimReport,
  };
};
