import { useCompanyMutations } from "@app/entities/company/mutations";
import { useCompanyQueries } from "@app/entities/company/queries";
import notification from "antd/lib/notification";
import { useEffect, useState } from "react";

export const useCompaniesLoader = () => {
  const { addMemberToCompany, deleteMemberCompany, isMutationPending } =
    useCompanyMutations();
  const { getMemberCompanies } = useCompanyQueries();
  const { memberCompanies, isMemberCompaniesPending } = getMemberCompanies();
  const [isInactiveAssociationModalOpen, setIsInactiveAssociationModalOpen] =
    useState(false);

  useEffect(() => {
    if (memberCompanies?.length) {
      const inactiveAssociation = memberCompanies.find(
        (company) => company.status === "i",
      );
      if (inactiveAssociation) {
        setIsInactiveAssociationModalOpen(true);
      }
    }
  }, [memberCompanies]);

  const handleAddCompany = async (values: { [key: string]: string }) => {
    const memberActivationCode = values.memberActivationCode?.trim();
    if (!memberActivationCode) {
      return notification.error({ message: "Activation code is required" });
    }
    const memberActivationCodeOnlyNumbersAndLetters = /^[a-zA-Z0-9]*$/;
    if (!memberActivationCodeOnlyNumbersAndLetters.test(memberActivationCode)) {
      return notification.error({
        message: "Activation code must contain only letters and numbers",
      });
    }
    addMemberToCompany({ memberActivationCode });
  };

  const handleDeleteCompany = async (companyId: number) => {
    deleteMemberCompany({ companyId });
  };

  const adminRedirectUrl =
    process.env.NODE_ENV === "production"
      ? `https://admin.swarmreport.org/#/company`
      : process.env.NODE_ENV === "staging"
        ? `https://staging.admin.swarmreport.org/#/company`
        : `http://localhost:8080/#/company`;

  const isLoading = isMutationPending || isMemberCompaniesPending;
  return {
    adminRedirectUrl,
    handleAddCompany,
    handleDeleteCompany,
    isInactiveAssociationModalOpen,
    isLoading,
    memberCompanies,
    setIsInactiveAssociationModalOpen,
  };
};
