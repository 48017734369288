import axiosInstance from "@app/axiosInstance";
import {
  T_LoginData,
  T_MemberAvailability,
  T_MemberInformation,
} from "@app/entities/member/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";

import { useNavigate } from "react-router";

export const useMemberMutations = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: sendMagicLink, isPending: isSendMagicLinkPending } =
    useMutation({
      mutationFn: ({ email, recaptchaToken }: T_LoginData) =>
        axiosInstance.post("/oauth/magic-login/member", {
          destination: email,
          recaptchaToken,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes("member"),
        });
        notification.success({
          description: "Success! Check for your email for a link to login.",
          message: "Success",
        });
      },
      onError: (error) => {
        notification.error({
          description: error.message,
          message:
            "Something went wrong sending a link. Please refresh the page and try again.",
        });
      },
    });

  const { mutate: updateAvailability, isPending: isUpdateAvailabilityPending } =
    useMutation({
      mutationFn: ({
        key,
        value,
      }: {
        key: keyof T_MemberAvailability;
        value: string | boolean;
      }) => axiosInstance.put("/member/availability", { [key]: value }),
      onSuccess: () => {
        notification.success({
          description: "Your availability has been updated.",
          message: "Success",
        });
        queryClient.invalidateQueries({ queryKey: ["member", "availability"] });
      },
      onError: (error) => {
        notification.error({
          description: error.message,
          message: "Failed to update availability.",
        });
      },
    });

  const {
    mutate: updateMemberInformation,
    isPending: isUpdateMemberInformationPending,
  } = useMutation({
    mutationKey: ["member", "information"],
    mutationFn: (data: T_MemberInformation) =>
      axiosInstance.put(`/member/information`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["member", "information"] });
      return notification.success({
        description: "Your information hase been updated.",
        message: "Success",
      });
    },
  });

  const { mutate: logout, isPending: isLogoutPending } = useMutation({
    mutationKey: ["logout"],
    mutationFn: () => axiosInstance.delete("/oauth/logout"),
    onSuccess: () => {
      // Clear the member query cache
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.some((key) => key === "member"),
      });
      navigate("/");
    },
    onError: (error) => {
      console.error(error);
      notification.error({
        description: "Something went wrong logging you out.",
        message: "Error",
      });
      navigate("/");
    },
  });

  const {
    mutate: updateMemberOnboarding,
    isPending: isUpdateMemberOnboardingPending,
  } = useMutation({
    mutationFn: (data: { onboardingId: number; status: string }) =>
      axiosInstance.put(`/member/onboarding`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["member", "information"] });
      return notification.success({
        description: "Your onboarding information has been updated.",
        message: "Success",
      });
    },
  });

  const isMutationPending =
    isSendMagicLinkPending ||
    isUpdateAvailabilityPending ||
    isUpdateMemberInformationPending ||
    isUpdateMemberOnboardingPending ||
    isLogoutPending;
  return {
    sendMagicLink,
    isMutationPending,
    updateAvailability,
    updateMemberInformation,
    logout,
    updateMemberOnboarding,
  };
};
